import { clone, remove } from 'lodash'
import { AiOutlineClose } from 'react-icons/ai'

import { Button, Select, TextInput } from 'Components/Form'

export const dataDefault = {
    material_id: '',
    weight: '',
    batch_reject_type_id: '',
    reject_type: '',
    notes: '',
}

export default function BatchRejectMaterial({ index, datas, data, handleChange, touched, errors, materials, batchRejectTypes }) {
    var name = `batch_reject_material[${index}].`

    const setDate = (name, value) => {
        var saveMaterial = clone(datas)
        saveMaterial = saveMaterial.map((e, i) => {
            if (i === index) {
                e[name] = value
            }
            return e
        })
        handleChange('batch_reject_material', saveMaterial)
    }

    return (
        <div className="flex flex-col gap-3">
            <div className="grid grid-cols-3 gap-3 sm:grid-cols-6">
                <Select
                    id={`${name}material_id`}
                    name={`${name}material_id`}
                    label={index === 0 ? 'Material' : null}
                    placeholder="Select"
                    value={data.material_id}
                    options={materials.map(e => ({
                        id: e.id,
                        label: e.title,
                    }))}
                    onChange={e => setDate(`material_id`, e.target.value)}
                    error={touched?.material_id && errors?.material_id}
                    className="col-span-3 sm:col-span-2"
                />
                <TextInput
                    type="number"
                    id={`${name}weight`}
                    name={`${name}weight`}
                    label={index === 0 ? 'Weight' : null}
                    placeholder="Weight"
                    value={data.weight}
                    onChange={e => setDate(`weight`, e.target.value)}
                    error={touched?.weight && errors?.weight}
                    min="1"
                    className="col-span-3 sm:col-span-2"
                />
                <Select
                    id={`${name}batch_reject_type_id`}
                    name={`${name}batch_reject_type_id`}
                    label={index === 0 ? 'Batch Reject Type' : null}
                    placeholder="Batch Reject Type"
                    value={data.batch_reject_type_id}
                    options={batchRejectTypes.map(e => ({
                        id: e.id,
                        label: e.name,
                    }))}
                    onChange={e => setDate(`batch_reject_type_id`, e.target.value)}
                    error={touched?.batch_reject_type_id && errors?.batch_reject_type_id}
                    className="col-span-3 sm:col-span-2"
                />
                {data.batch_reject_type_id === 'others' ? (
                    <TextInput
                        id={`${name}reject_type`}
                        name={`${name}reject_type`}
                        // label={index === 0 ? 'Reject Type' : null}
                        placeholder="Reject Type"
                        value={data.reject_type}
                        onChange={e => setDate(`reject_type`, e.target.value)}
                        error={touched?.reject_type && errors?.reject_type}
                        className="col-span-3 sm:col-span-2"
                    />
                ) : null}
                <TextInput
                    id={`${name}notes`}
                    name={`${name}notes`}
                    // label={index === 0 ? 'Notes' : null}
                    placeholder="Notes"
                    value={data.notes}
                    onChange={e => setDate(`notes`, e.target.value)}
                    error={touched?.notes && errors?.notes}
                    className="col-span-2 sm:col-span-2"
                />
                <div>
                    <div className={`flex items-center gap-3`}>
                        <Button
                            type="button"
                            className="text-white border-danger bg-danger"
                            onClick={() => {
                                var saveTask = clone(datas)
                                remove(saveTask, (n, key) => key === index)
                                handleChange('batch_reject_material', saveTask)
                            }}
                        >
                            <AiOutlineClose size={20} />
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}
