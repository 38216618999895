import { Form, Formik } from 'formik'
import * as Yup from 'yup'

import { Button, TextInput } from 'Components/Form'
import { Breadcrumb } from 'Components/Layout/Header'
import { Cart, Tooltip } from 'Components/Ui'
import useAuth from 'Utils/Hooks/useAuth'
import { FiInfo } from 'react-icons/fi'

const validationSchema = Yup.object().shape({
    current_password: Yup.string().required('The current password field is required.'),
    password: Yup.string().required('The password field is required.'),
    password_confirmation: Yup.string().required('The password field is required.'),
})

export default function Password() {
    const { changePassword } = useAuth()
    return (
        <>
            <Breadcrumb title="Change Password" />
            <Cart title="Change Password" className="xl:w-1/2">
                <Formik
                    initialValues={{
                        current_password: '',
                        password: '',
                        password_confirmation: '',
                    }}
                    validationSchema={validationSchema}
                    onSubmit={changePassword}
                >
                    {({ touched, errors, isSubmitting }) => (
                        <Form className="flex flex-col gap-3">
                            <TextInput
                                id="current_password"
                                name="current_password"
                                label="Current Password"
                                autoComplete="current-password"
                                password
                                error={touched.current_password && errors.current_password}
                            />
                            <TextInput
                                id="password"
                                name="password"
                                label={
                                    <div className="flex items-center gap-2">
                                        Password
                                        <Tooltip
                                            title="Password must contain at least 8 characters, including 1 uppercase letter, 1 lowercase letter,1 number, and 1 symbols"
                                            placement="bottom"
                                        >
                                            <FiInfo size={16} />
                                        </Tooltip>
                                    </div>
                                }
                                placeholder="Password"
                                autoComplete="new-password"
                                password
                                error={touched.password && errors.password}
                            />
                            <TextInput
                                id="password_confirmation"
                                name="password_confirmation"
                                label="Confirm Password"
                                autoComplete="new-password"
                                password
                                error={touched.password_confirmation && errors.password_confirmation}
                            />
                            <div className="flex items-center gap-3 mt-3">
                                <Button
                                    className="text-white border-primary bg-primary dark:border-secondary dark:bg-secondary"
                                    disabled={isSubmitting}
                                    type="submit"
                                >
                                    Save
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </Cart>
        </>
    )
}
