import { clone } from 'lodash'

import { Select, TextInput } from 'Components/Form'
import { FiInfo } from 'react-icons/fi'
import { Tooltip } from 'Components/Ui'

export default function MaterialTypeAccept({ index, datas, data, handleChange, touched, errors, batchRejectTypes = [] }) {
    var name = `material_type[${index}].`
    const setDate = (name, value) => {
        var saveTask = clone(datas)
        saveTask = saveTask.map((e, i) => {
            var val = clone(e)
            if (i === index) {
                val[name] = value
                if (name === 'accept_bags') {
                    val.reject_bags = (data.bags_count ?? 0) - value
                }
            }
            return val
        })
        handleChange('material_type', saveTask)
    }

    return (
        <div className="grid grid-cols-1 gap-3 pt-3 border-t md:grid-cols-11 border-stroke">
            <div className="flex flex-col col-span-10 gap-3">
                <div className="grid grid-cols-5 gap-3 md:grid-cols-5">
                    <div className="col-span-1">
                        <p className="pt-8">
                            {data.material.title} ({data.bags_count})
                        </p>
                    </div>
                    <TextInput
                        type="number"
                        id={`${name}accept_bags`}
                        name={`${name}accept_bags`}
                        label={
                            <div className="flex items-center">
                                Accepted Bags
                                <Tooltip
                                    title="Reduce this count by clicking down arrow if there are any Rejections"
                                    placement="top"
                                >
                                    <FiInfo size={16} className="ml-1 text-gray-500 cursor-pointer" />
                                </Tooltip>
                            </div>
                        }
                        placeholder="Accepted Bags"
                        value={data.accept_bags}
                        onChange={e => setDate(`accept_bags`, e.target.value)}
                        error={touched?.accept_bags && errors?.accept_bags}
                        min="0"
                        max={data.bags_count}
                        className="col-span-2 sm:col-span-2"
                    />
                    <TextInput
                        type="number"
                        id={`${name}accept_weight`}
                        name={`${name}accept_weight`}
                        label={
                            <div className="flex items-center">
                                Default Weight
                                <Tooltip
                                    title="Weight has been calculated by default and does not need to be adjusted unless it has been weighed."
                                    placement="top"
                                >
                                    <FiInfo size={16} className="ml-1 text-gray-500 cursor-pointer" />
                                </Tooltip>
                            </div>
                        }
                        placeholder="Default Weight"
                        value={data.accept_weight}
                        onChange={e => setDate(`accept_weight`, e.target.value)}
                        error={touched?.accept_weight && errors?.accept_weight}
                        min="0"
                        className="col-span-2 sm:col-span-2"
                        icon="KG"
                    />
                </div>
                {(data.reject_bags ?? 0) > 0 || (data.reject_weight ?? 0) > 0 ? (
                    <div className="grid grid-cols-4 gap-3 md:grid-cols-8">
                        <TextInput
                            type="number"
                            id={`${name}reject_bags`}
                            name={`${name}reject_bags`}
                            label="Reject Bags"
                            placeholder="Reject Bags"
                            value={data.reject_bags}
                            onChange={e => setDate(`reject_bags`, e.target.value)}
                            error={touched?.reject_bags && errors?.reject_bags}
                            min="1"
                            className="col-span-2 sm:col-span-2"
                            disabled
                        />
                        <TextInput
                            type="number"
                            id={`${name}reject_weight`}
                            name={`${name}reject_weight`}
                            label="Reject Weight"
                            placeholder="Reject Weight"
                            value={data.reject_weight}
                            onChange={e => setDate(`reject_weight`, e.target.value)}
                            error={touched?.reject_weight && errors?.reject_weight}
                            min="1"
                            className="col-span-2 sm:col-span-2"
                            icon="KG"
                        />
                        <Select
                            id={`${name}batch_reject_type_id`}
                            name={`${name}batch_reject_type_id`}
                            label="Batch Reject Type"
                            placeholder="Batch Reject Type"
                            value={data.batch_reject_type_id}
                            options={batchRejectTypes.map(e => ({
                                id: e.id,
                                label: e.name,
                            }))}
                            onChange={e => setDate(`batch_reject_type_id`, e.target.value)}
                            error={touched?.batch_reject_type_id && errors?.batch_reject_type_id}
                            className="col-span-3 sm:col-span-2"
                            disabled={!((data.reject_bags ?? 0) > 0 || (data.reject_weight ?? 0) > 0)}
                        />
                        {data.batch_reject_type_id === 'others' ? (
                            <TextInput
                                id={`${name}reject_type`}
                                name={`${name}reject_type`}
                                label="Reject Type"
                                placeholder="Reject Type"
                                value={data.reject_type}
                                onChange={e => setDate(`reject_type`, e.target.value)}
                                error={touched?.reject_type && errors?.reject_type}
                                className="col-span-3 sm:col-span-2"
                                disabled={!((data.reject_bags ?? 0) > 0 || (data.reject_weight ?? 0) > 0)}
                            />
                        ) : null}
                    </div>
                ) : null}
            </div>
        </div>
    )
}
