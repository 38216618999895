import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
    apiListBagTypes,
    apiListBatchRejectTypes,
    apiListBulkoBags,
    apiListCages,
    apiListCollectorCollectionPoints,
    apiListCollectors,
    apiListMaterials,
    apiListProjects,
} from 'Services/UserService'

export const getCollectors = createAsyncThunk('user/list/getCollectors', async id => {
    if (id === 0) {
        const { data } = await apiListCollectorCollectionPoints()
        return data
    } else {
        const { data } = await apiListCollectors()
        return data
    }
})

export const getProjects = createAsyncThunk('user/list/getProjects', async () => {
    const { data } = await apiListProjects()
    return data
})

export const getMaterials = createAsyncThunk('user/list/getMaterials', async () => {
    const { data } = await apiListMaterials()
    return data
})

export const getBatchRejectTypes = createAsyncThunk('user/list/getBatchRejectTypes', async () => {
    const { data } = await apiListBatchRejectTypes()
    data.push({
        id: 'others',
        name: 'Others',
    })
    return data
})

export const getCages = createAsyncThunk('user/list/getCages', async () => {
    const { data } = await apiListCages()
    return data
})

export const getBulkoBags = createAsyncThunk('user/list/getBulkoBags', async () => {
    const { data } = await apiListBulkoBags()
    return data
})

export const getBagTypes = createAsyncThunk('user/list/getBagTypes', async () => {
    const { data } = await apiListBagTypes()
    return data
})

export const listSlice = createSlice({
    name: 'user/list',
    initialState: {
        collectors: [],
        projects: [],
        materials: [],
        batchRejectTypes: [],
        cages: [],
        bulko_bags: [],
        bag_types: [],
    },
    extraReducers: builder => {
        builder
            .addCase(getCollectors.fulfilled, (state, { payload }) => {
                state.collectors = payload
            })
            .addCase(getCollectors.rejected, state => {
                state.collectors = []
            })

            .addCase(getProjects.fulfilled, (state, { payload }) => {
                state.projects = payload
            })
            .addCase(getProjects.rejected, state => {
                state.projects = []
            })

            .addCase(getMaterials.fulfilled, (state, { payload }) => {
                state.materials = payload
            })
            .addCase(getMaterials.rejected, state => {
                state.materials = []
            })

            .addCase(getBatchRejectTypes.fulfilled, (state, { payload }) => {
                state.batchRejectTypes = payload
            })
            .addCase(getBatchRejectTypes.rejected, state => {
                state.batchRejectTypes = []
            })

            .addCase(getCages.fulfilled, (state, { payload }) => {
                state.cages = payload
            })
            .addCase(getCages.rejected, state => {
                state.cages = []
            })

            .addCase(getBulkoBags.fulfilled, (state, { payload }) => {
                state.bulko_bags = payload
            })
            .addCase(getBulkoBags.rejected, state => {
                state.bulko_bags = []
            })

            .addCase(getBagTypes.fulfilled, (state, { payload }) => {
                state.bag_types = payload
            })
            .addCase(getBagTypes.rejected, state => {
                state.bag_types = []
            })
    },
})

export default listSlice.reducer
