import { Button } from 'Components/Form'
import { Cart, Info } from 'Components/Ui'
import { uniq } from 'lodash'
import moment from 'moment/moment'
import prettyBytes from 'pretty-bytes'
import { Link } from 'react-router-dom'
import { projectType, wasteDropsLocation, storeName, userName } from 'Utils/helper'

export default function View({ title, view, cancel_url = null }) {
    return (
        <Cart title={title} className="xl:w-1/2">
            <div className="grid gap-6">
                <div className="grid gap-3">
                    <Info title="Collection Point Dropped off at">{storeName(view.collection_point)}</Info>
                    <Info title="Collector">{userName(view.collector)}</Info>
                    <Info title="Project">{view.project ? view.project.address : 'N/A'}</Info>
                    <Info title="Type">{projectType(view.type, true)}</Info>
                    <Info title="Address">{view.address}</Info>
                    <Info title="Location">{wasteDropsLocation(view.location, true)}</Info>
                    <Info title="Notes">{view.notes}</Info>
                    <Info title="Created At">{moment(view.created_at).format('lll')}</Info>
                    <Info title="Bulka Bag Bin">
                        {uniq(view.bulko_bag.filter(e => e.bulko_bag).map(e => e.bulko_bag.number)).join(', ')}
                    </Info>
                    <h3 className="pt-6 font-medium text-black dark:text-white">Images</h3>
                    {(view.image_urls ?? []).map((img, index) => (
                        <Info
                            key={index}
                            title={
                                <a
                                    href={img.file_url + '?download=' + img.name}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="cursor-pointer"
                                >
                                    <p className="text-sm text-black dark:text-white hover:text-primary dark:hover:text-secondary">
                                        {img.name}
                                    </p>
                                </a>
                            }
                        >
                            {prettyBytes(Number(img.size))}
                        </Info>
                    ))}
                    <h3 className="pt-6 font-medium text-black dark:text-white">Materials</h3>
                    {(view.material_type ?? []).map((material, index) => (
                        <Info
                            key={index}
                            title={`${material.material?.title ?? 'Unknown Material'}  - Accept (${material.accept_bags} Bags - ${material.accept_weight} KG) - Reject (${material.reject_bags} Bags - ${material.reject_weight} KG)`}
                        />
                    ))}
                </div>
            </div>
            {cancel_url ? (
                <div className="flex items-center gap-3 mt-6">
                    <Link to={cancel_url}>
                        <Button type="button" className="text-primary border-primary dark:text-secondary dark:border-secondary">
                            Cancel
                        </Button>
                    </Link>
                </div>
            ) : null}
        </Cart>
    )
}
