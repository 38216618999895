import { clone, remove } from 'lodash'
import { AiOutlineClose, AiOutlinePlus } from 'react-icons/ai'
import { useState } from 'react'
import { Button, Select, TextInput } from 'Components/Form'
import { BagTypes } from 'Utils/helper'
import { Tooltip } from 'Components/Ui'
import { FiInfo } from 'react-icons/fi'

export const dataDefault = {
    material_id: '',
    types: '0',
    bags_count: '',
    comment: '',
}

export default function BagRequests({ index, datas, data, setFieldValue, touched, errors, materials }) {
    const name = `material_type[${index}].`
    const [selectedType, setSelectedType] = useState(data.types || '0')

    const handleTypeChange = e => {
        const value = e.target.value
        setSelectedType(value)
        setFieldValue(`${name}types`, value)
    }
    const bagCountLabel = selectedType === '1' ? 'No. of Boxes (50 bags ea)' : 'Number of Bags'

    return (
        <div className="flex flex-col gap-3">
            <div className={`grid grid-cols-3 gap-3 sm:grid-cols-9`}>
                <Select
                    id={`${name}types`}
                    name={`${name}types`}
                    label="Bag Types"
                    value={selectedType}
                    options={BagTypes()}
                    onChange={handleTypeChange}
                    error={touched?.types && errors?.types}
                    className="col-span-3 sm:col-span-2"
                />
                <Select
                    id={`${name}material_id`}
                    name={`${name}material_id`}
                    label={
                        <div className="flex items-center gap-2">
                            Material
                            {selectedType === '0' ? null : (
                                <Tooltip title="Select any of the below Material Type" placement="bottom">
                                    <FiInfo size={16} />
                                </Tooltip>
                            )}
                        </div>
                    }
                    placeholder="Type"
                    value={data.material_id}
                    options={materials.map(e => ({
                        id: e.id,
                        label: e.title,
                    }))}
                    onChange={e => setFieldValue(`${name}material_id`, e.target.value)}
                    error={touched?.material_id && errors?.material_id}
                    className="col-span-3 sm:col-span-2"
                    disabled={selectedType === '0'}
                />
                <TextInput
                    type="number"
                    id={`${name}bags_count`}
                    name={`${name}bags_count`}
                    label={bagCountLabel}
                    placeholder={bagCountLabel}
                    value={data.bags_count}
                    onChange={e => setFieldValue(`${name}bags_count`, e.target.value)}
                    error={touched?.bags_count && errors?.bags_count}
                    min="1"
                    className="col-span-3 sm:col-span-2"
                />
                <TextInput
                    id={`${name}comment`}
                    name={`${name}comment`}
                    label="Comment"
                    placeholder="Comment"
                    value={data.comment}
                    onChange={e => setFieldValue(`${name}comment`, e.target.value)}
                    error={touched?.comment && errors?.comment}
                    className="col-span-2 sm:col-span-2"
                />
                <div>
                    <div className={`flex items-center gap-3 pt-7`}>
                        {index + 1 === datas.length ? (
                            <Button
                                type="button"
                                className="text-white border-primary bg-primary dark:border-secondary dark:bg-secondary"
                                onClick={() => {
                                    const saveTask = clone(datas)
                                    saveTask.push(dataDefault)
                                    setFieldValue('material_type', saveTask)
                                }}
                            >
                                <AiOutlinePlus size={20} />
                            </Button>
                        ) : (
                            <Button
                                type="button"
                                className="text-white border-danger bg-danger"
                                onClick={() => {
                                    const saveTask = clone(datas)
                                    remove(saveTask, (n, key) => key === index)
                                    setFieldValue('material_type', saveTask)
                                }}
                            >
                                <AiOutlineClose size={20} />
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}
