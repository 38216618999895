import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment/moment'
import { clone, map } from 'lodash'
import { FiAlertCircle } from 'react-icons/fi'

import { Modal } from 'Components/Ui'
import { Button, Select, TextInput } from 'Components/Form'
import { getBatchRejectTypes, getBulkoBags, getCages, getMaterials } from 'Store/admin/listSlice'
import { getMaterials as getUserMaterials, getBatchRejectTypes as getUserBatchRejectTypes } from 'Store/user/listSlice'
import { BulkoBag, MaterialTypeAccept } from 'Components/User/WasteDrops'
import { setDefaultDate } from 'Components/User/WasteDrops/BulkoBag'
import { BatchMaterial, BatchRejectMaterial } from 'Components/User/Batches'
import { dataDefault } from 'Components/User/Batches/BatchMaterial'
import { dataDefault as dataDefaultRejectMaterial } from 'Components/User/Batches/BatchRejectMaterial'
import { AiOutlinePlus } from 'react-icons/ai'

export default function ConfirmationModal({
    children,
    data,
    onConfirm,
    title = '',
    message = '',
    className = '',
    maxWidth = 'md',
    material_types = [],
}) {
    const dispatch = useDispatch()
    const [confirming, setConfirming] = useState(false)
    const [datas, setData] = useState(data ?? {})

    const { auth, admin, user } = useSelector(state => state)
    const guard = auth?.user?.guard ?? 'user'

    const handleChange = (name, value) => setData({ ...datas, [name]: value })

    const closeModal = () => setConfirming(false)
    return (
        <div>
            <button
                className={className}
                onClick={() => {
                    if ('get_cages' in datas) {
                        dispatch(getCages({ type: 1, id: datas.get_cages }))
                    }
                    if ('material_type' in datas) {
                        if (guard === 'admin') {
                            dispatch(getBatchRejectTypes())
                        } else {
                            dispatch(getUserBatchRejectTypes())
                        }
                    }
                    if ('bulko_bag' in datas) {
                        if (guard === 'admin') {
                            dispatch(getBulkoBags({ type: 0, id: datas.collection_point_id }))
                                .unwrap()
                                .then(bulko_bags =>
                                    handleChange('bulko_bag', setDefaultDate(material_types, datas.bulko_bag, bulko_bags))
                                )
                        } else {
                            handleChange(
                                'bulko_bag',
                                setDefaultDate(material_types, datas.bulko_bag, user?.list?.bulko_bags ?? [])
                            )
                        }
                    }
                    if ('batch_material' in datas) {
                        if (guard === 'admin') {
                            dispatch(getMaterials())
                            dispatch(getBatchRejectTypes())
                        } else {
                            dispatch(getUserMaterials())
                            dispatch(getUserBatchRejectTypes())
                        }
                        setData({
                            ...datas,
                            batch_material: [clone(dataDefault)],
                            batch_reject_material: [],
                        })
                    }
                    setConfirming(true)
                }}
            >
                {children}
            </button>
            <Modal show={confirming} onClose={closeModal} maxWidth={maxWidth}>
                <div className="grid gap-3 p-6 text-center">
                    <span className="inline-block mx-auto">
                        <div className="flex items-center justify-center w-20 h-20 transition rounded-full bg-warning/10 text-warning">
                            <FiAlertCircle size={40} />
                        </div>
                    </span>
                    <h3 className="text-xl font-bold text-black dark:text-white sm:text-2xl">
                        {title ? title : 'Confirmation Alert!'}
                    </h3>
                    <p className="font-medium">{message ? message : 'Are you sure you want to change status?'}</p>
                    {'pickup_by' in datas && (
                        <div className="flex flex-col gap-3 text-left">
                            <Select
                                id="affiliate_id"
                                name="affiliate_id"
                                label="Recycler"
                                placeholder="Recycler"
                                value={datas.affiliate_id}
                                options={(admin?.list?.affiliates ?? []).map(e => ({
                                    id: e.id,
                                    label: e.business_name,
                                }))}
                                onChange={e => handleChange('affiliate_id', e.target.value)}
                            />
                            <TextInput
                                type="datetime-local"
                                id="pickup"
                                name="pickup"
                                label="Expected Pickup Date"
                                value={datas.pickup}
                                onChange={e =>
                                    handleChange('pickup', moment(e.target.value).locale('en').format('YYYY-MM-DD HH:mm'))
                                }
                                min={moment().format('YYYY-MM-DD HH:mm')}
                            />
                            <TextInput
                                id="pickup_by"
                                name="pickup_by"
                                label="Transport Company"
                                value={datas.pickup_by}
                                onChange={e => handleChange('pickup_by', e.target.value)}
                            />
                            <TextInput
                                id="manifest_no"
                                name="manifest_no"
                                label="Manifest No"
                                value={datas.manifest_no}
                                onChange={e => handleChange('manifest_no', e.target.value)}
                            />
                        </div>
                    )}
                    {'pickup_bulko_bag' in datas && (
                        <div className="flex flex-col gap-3 text-left">
                            {datas.pickup_bulko_bag.map((data, index) => {
                                var name = `pickup_bulko_bag[${index}].`
                                return (
                                    <div className="grid grid-cols-1 gap-3 sm:grid-cols-2" key={index}>
                                        <Select
                                            id={`${name}cage_id`}
                                            name={`${name}cage_id`}
                                            label={data.bulko_bag.number}
                                            placeholder="Box"
                                            value={data.cage_id ?? ''}
                                            options={(('get_cages' in datas ? admin : user).list?.cages ?? []).map(e => ({
                                                id: e.id,
                                                label: e.number,
                                            }))}
                                            onChange={e =>
                                                handleChange(
                                                    'pickup_bulko_bag',
                                                    map(clone(datas.pickup_bulko_bag), n => ({
                                                        ...n,
                                                        cage_id: n.id === data.id ? e.target.value : n.cage_id,
                                                    }))
                                                )
                                            }
                                        />
                                        <TextInput
                                            type="number"
                                            id={`${name}weight`}
                                            name={`${name}weight`}
                                            label="Weight"
                                            placeholder="Weight"
                                            value={data.weight ?? ''}
                                            min="1"
                                            icon="KG"
                                            onChange={e =>
                                                handleChange(
                                                    'pickup_bulko_bag',
                                                    map(clone(datas.pickup_bulko_bag), n => ({
                                                        ...n,
                                                        weight: n.id === data.id ? e.target.value : n.weight,
                                                    }))
                                                )
                                            }
                                        />
                                    </div>
                                )
                            })}
                        </div>
                    )}
                    {'material_type' in datas && (
                        <div className="flex flex-col gap-3 text-left">
                            {datas.material_type.map((data, index) => (
                                <MaterialTypeAccept
                                    key={index}
                                    index={index}
                                    datas={datas.material_type}
                                    data={data}
                                    handleChange={handleChange}
                                    batchRejectTypes={(guard === 'admin' ? admin : user)?.list?.batchRejectTypes ?? []}
                                />
                            ))}
                        </div>
                    )}
                    {'bulko_bag' in datas && (
                        <div className="flex flex-col gap-3 text-left">
                            {datas.bulko_bag.map((data, index) => (
                                <BulkoBag
                                    key={index}
                                    index={index}
                                    datas={datas.bulko_bag}
                                    data={data}
                                    handleChange={handleChange}
                                    material_types={material_types}
                                    bulko_bags={(guard === 'admin' ? admin : user)?.list?.bulko_bags ?? []}
                                />
                            ))}
                        </div>
                    )}
                    {'batch_material' in datas && (
                        <div className="flex flex-col gap-3 text-left">
                            <p className="font-bold text-black dark:text-white sm:text-lg">Recycled Material Created</p>
                            {datas.batch_material.map((data, index) => (
                                <BatchMaterial
                                    key={index}
                                    index={index}
                                    datas={datas.batch_material}
                                    data={data}
                                    handleChange={handleChange}
                                    materials={(guard === 'admin' ? admin : user)?.list?.materials ?? []}
                                />
                            ))}
                            <div className="flex flex-auto gap-3">
                                <p className="font-bold text-black dark:text-white sm:text-lg">Recycling Waste</p>
                                <Button
                                    type="button"
                                    className="text-white border-primary bg-primary dark:border-secondary dark:bg-secondary"
                                    onClick={() => {
                                        var saveTask = clone(datas.batch_reject_material)
                                        saveTask.push(clone(dataDefaultRejectMaterial))
                                        handleChange('batch_reject_material', saveTask)
                                    }}
                                >
                                    <AiOutlinePlus size={20} />
                                </Button>
                            </div>
                            {datas.batch_reject_material.map((data, index) => (
                                <BatchRejectMaterial
                                    key={index}
                                    index={index}
                                    datas={datas.batch_reject_material}
                                    data={data}
                                    handleChange={handleChange}
                                    materials={(guard === 'admin' ? admin : user)?.list?.materials ?? []}
                                    batchRejectTypes={(guard === 'admin' ? admin : user)?.list?.batchRejectTypes ?? []}
                                />
                            ))}
                        </div>
                    )}
                    {'expected_delivery_date' in datas && (
                        <div className="flex flex-col gap-3 text-left">
                            <TextInput
                                type="date"
                                id="expected_delivery_date"
                                name="expected_delivery_date"
                                label="Expected Delivery Date"
                                value={datas.expected_delivery_date}
                                onChange={e =>
                                    handleChange(
                                        'expected_delivery_date',
                                        moment(e.target.value).locale('en').format('YYYY-MM-DD')
                                    )
                                }
                                min={moment().format('YYYY-MM-DD')}
                            />
                        </div>
                    )}
                    <div className="grid grid-cols-1 gap-3 pt-5 md:grid-cols-2">
                        <Button type="button" className="w-full text-white border-danger bg-danger" onClick={closeModal}>
                            No
                        </Button>
                        <Button
                            className="w-full text-white border-primary bg-primary dark:border-secondary dark:bg-secondary"
                            onClick={() => {
                                closeModal()
                                onConfirm(datas)
                            }}
                        >
                            Yes
                        </Button>
                    </div>
                </div>
            </Modal>
        </div>
    )
}
