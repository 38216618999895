import { Tab } from '@headlessui/react'

var vs = 1

const Tabs = ({ children }) => {
    var className = 'py-4 text-sm font-medium border-b-2 hover:text-primary dark:hover:text-secondary md:text-base'
    var select = 'text-primary border-primary dark:text-secondary dark:border-secondary'
    var deSelect = 'border-transparent'
    if ([2, 3].includes(vs)) {
        className =
            'px-3 py-2 text-sm font-medium rounded-md hover:bg-primary hover:text-white dark:hover:bg-secondary md:text-base lg:px-6'
        select = 'bg-primary dark:bg-secondary text-white'
        deSelect = 'bg-gray dark:bg-graydark text-black dark:text-white'
    }
    return <Tab className={({ selected }) => `${className} ${selected ? select : deSelect}`}>{children}</Tab>
}

const Group = ({ children, version = 1, onChange }) => {
    vs = version
    return <Tab.Group onChange={onChange}>{children}</Tab.Group>
}

const List = ({ children }) => {
    var className = 'flex flex-wrap gap-3 border-b border-stroke dark:border-strokedark sm:gap-10'
    if (vs === 2) {
        className = 'flex flex-wrap gap-3 px-4 py-3 border rounded-lg border-stroke dark:border-strokedark'
    } else if (vs === 3) {
        className = 'flex flex-wrap gap-3 pb-5 border-b border-stroke dark:border-strokedark'
    }
    return <Tab.List className={className}>{children}</Tab.List>
}

const Panels = ({ children, className }) => {
    return <Tab.Panels className={className}>{children}</Tab.Panels>
}

const Panel = ({ children }) => {
    return <Tab.Panel className="font-medium leading-relaxed">{children}</Tab.Panel>
}

Tabs.Group = Group
Tabs.List = List
Tabs.Panels = Panels
Tabs.Panel = Panel

export default Tabs
